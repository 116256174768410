import React, { Component } from 'react';
import mondaySdk from "monday-sdk-js";
import { Dropdown, TextField } from "monday-ui-react-core";
import { Button } from "monday-ui-react-core";
import axios from 'axios';

const monday = mondaySdk();
monday.setApiVersion("2023-10");

export class AppSettings extends Component {
    static displayName = AppSettings.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            cultures: undefined,
            selectedCulture: null,
            hasEmailServer: false,
            smtpHost: null,
            smtpUser: null,
            smtpPassword: null,
            generateRight: undefined,
            readRight: undefined,
            hasRightConfiguration: false,
        };
        this.userLanguage = 'en';
        this.mondayContext = window.mondayContext;
        if (this.mondayContext)
            this.userLanguage = this.mondayContext.user.currentLanguage;
        this.handleSelectedCultureChange = this.handleSelectedCultureChange.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleSmtpHostChange = this.handleSmtpHostChange.bind(this);
        this.handleSmtpUserChange = this.handleSmtpUserChange.bind(this);
        this.handleSmtpPasswordChange = this.handleSmtpPasswordChange.bind(this);
        this.handleReadRightChange = this.handleReadRightChange.bind(this);
        this.handleGenerateRightChange = this.handleGenerateRightChange.bind(this);
    }

    handleSmtpHostChange(text) {
        this.setState({ smtpHost: text });
    }

    handleSmtpUserChange(text) {
        this.setState({ smtpUser: text });
    }

    handleSmtpPasswordChange(text) {
        this.setState({ smtpPassword: text });
    }

    componentDidMount() {
        this.populateSettingsData();
    }

    async populateSettingsData() {
        var sessionToken = window.mondaySessionToken;
        if (!sessionToken) { 
            const urlParams = new URLSearchParams(window.location.search);
            sessionToken = urlParams.get('sessionToken');
        }            
        const response = await fetch('accountmanagement/AppSettings?UserId=' + this.mondayContext.user.id + '&SessionToken=' + sessionToken);
        const data = await response.json();
        console.log(data);
        var cultures = data.cultures.map(c => ({ value: c.value, label: c.text }));
        var selectedCulture = cultures.find(c => c.value === data.culture);
        this.setState({
            cultures: cultures,
            selectedCulture: selectedCulture,
            loading: false,
            hasEmailServer: data.hasEmailServer,
            smtpHost: data.smtpHost,
            smtpUser: data.smtpUser,
            // Don't get SMTP password from server
            isReportGenerator: data.isReportGenerator,
            generateRight: data.generateRight,
            readRight: data.readRight,
            hasRightConfiguration: data.hasRightConfiguration,
        });
    }

    handleSelectedCultureChange(culture) {
        this.setState({ selectedCulture: culture });        
    }

    async handleClickSave() {
        var sessionToken = window.mondaySessionToken;
        if (!sessionToken) {
            const urlParams = new URLSearchParams(window.location.search);
            sessionToken = urlParams.get('sessionToken');
        }            
        this.setState({ isSaving: true });
        const data = {
            sessionToken: sessionToken,
            culture: this.state.selectedCulture?.value,
            UserId: this.mondayContext.user.id,
            smtpHost: this.state.smtpHost,
            smtpUser: this.state.smtpUser,
            smtpPassword: this.state.smtpPassword,
            generateRight: this.state.generateRight,
            readRight: this.state.readRight
        }
        try {
            const response = await axios.post('accountmanagement/SaveAppSettings', data);
            console.log(response.data);
            if (response.data.result) {
                this.setState({ isSaving: false });
                monday.execute("notice", {
                    message: 'Settings saved successfully',
                    type: "info", // or "error" (red), or "info" (blue)
                    timeout: 3000,
                });
            } else {
                var errorMessage = 'Error saving settings: ' + response.data.error;
                console.log(errorMessage);
                alert(errorMessage);
                this.setState({ isSaving: false });
            }
        } catch (error) {
            console.error('Error:', error);
            alert(error);
            this.setState({ isSaving: false });
        }
    }

    handleReadRightChange(right) {
        this.setState({ readRight: right.value });
        if (right.value === 'Owner' && this.state.generateRight !== 'Owner')
            this.setState({ generateRight: right.value });
        if (right.value === 'Member' && this.state.generateRight === 'Guest')
            this.setState({ generateRight: right.value });
    }

    handleGenerateRightChange(right) {
        this.setState({ generateRight: right.value });
    }

    render() {
        if (this.state.loading)
            return (<p><em>Loading...</em></p>);
        var isAdmin = this.mondayContext.user.isAdmin;
        if (!isAdmin) {
            return (<p><em>Only monday.com admins can edit the settings</em></p>);
        }

        var cultures = this.state.cultures;
        var smtpSettings = null;
        let url = this.state.isReportGenerator ? "https://www.pdfreportgenerator.com" : "https://www.docexport.com";        
        if (this.state.hasEmailServer) {
            smtpSettings = (
                <div>
                    <h3>Email Server Settings (SMTP)</h3>
                    <p>By default, the integrated DocExport SMTP server is used for sending emails. If you would like to switch to your own server, please enter the details below. This configuration is only available on the Enterprise plan.</p>
                    <TextField
                        placeholder="SMTP Server"
                        title="SMTP Server"
                        wrapperClassName="app-spirit-textfield-recipename"
                        value={this.state.smtpHost}
                        onChange={this.handleSmtpHostChange} />
                    <TextField
                        placeholder="User name"
                        title="User name"
                        wrapperClassName="app-spirit-textfield-recipename"
                        value={this.state.smtpUser}
                        onChange={this.handleSmtpUserChange} />
                    <TextField
                        placeholder="Password"
                        title="Password"
                        type={TextField.types.PASSWORD}
                        wrapperClassName="app-spirit-textfield-recipename"
                        value={this.state.smtpPassword}
                        onChange={this.handleSmtpPasswordChange} />
                    <br />
                </div>
            );
        }

        var rightConfiguration = (
            <div>
                <h3>User permissions</h3>
                <p>User permissions can be configured here if you have the Professional or Enterprise plan.</p>
                <br />
            </div>
        );
        if (this.state.hasRightConfiguration) {
            var readRights = [
                { value: "Owner", label: "Board Owners and Admins" },
                { value: "Member", label: "Board Members" },
                { value: "Guest", label: "Board Members and Guests" },
                //{ value: "Viewer", label: "All users (including Viewer)" }
            ];
            let readRight = readRights.find(item => item.value === this.state.readRight);
            var generateRights = [{ value: "Owner", label: "Board Owners and Admins" }];
            if (this.state.readRight !== "Owner")
                generateRights.push({ value: "Member", label: "Board Members" });
            if (this.state.readRight !== "Owner" && this.state.readRight !== "Member")
                generateRights.push({ value: "Guest", label: "Board Members and Guests" });
            let generateRight = generateRights.find(item => item.value === this.state.generateRight);
            let documentsText = this.state.isReportGenerator ? "reports" : "documents";

            rightConfiguration = (
                <div>
                    <h3>User permissions</h3>
                    <p>Define who can read and download {documentsText}:</p>
                    <Dropdown
                        className="app-spirit-settings-dropdown"
                        onBlur={function noRefCheck() { }}
                        onChange={this.handleReadRightChange}
                        onClear={function noRefCheck() { }}
                        onFocus={function noRefCheck() { }}
                        onInputChange={function noRefCheck() { }}
                        onMenuClose={function noRefCheck() { }}
                        onMenuOpen={function noRefCheck() { }}
                        onOptionRemove={function noRefCheck() { }}
                        onOptionSelect={function noRefCheck() { }}
                        openMenuOnFocus={function noRefCheck() { }}
                        value={readRight}
                        options={readRights}
                        clearable={false}
                    />
                    <span className="gray-text">Note: The right does not apply to {documentsText} stored in columns on the monday.com board. Please configure the rights for the monday.com board and the column accordingly.</span>
                    <p>Define who can generate new {documentsText}:</p>
                    <Dropdown
                        className="app-spirit-settings-dropdown"
                        onBlur={function noRefCheck() { }}
                        onChange={this.handleGenerateRightChange}
                        onClear={function noRefCheck() { }}
                        onFocus={function noRefCheck() { }}
                        onInputChange={function noRefCheck() { }}
                        onMenuClose={function noRefCheck() { }}
                        onMenuOpen={function noRefCheck() { }}
                        onOptionRemove={function noRefCheck() { }}
                        onOptionSelect={function noRefCheck() { }}
                        openMenuOnFocus={function noRefCheck() { }}
                        value={generateRight}
                        options={generateRights}
                        clearable={false}
                    />
                    <br />
                </div>
            );
        }
        return (
            <div className="app-spirit-settings-div">
                <h3>Settings</h3>
                <p>Select a culture (language and country) to set your formatting preferences for all date, time and number fields in your documents.</p>
                <Dropdown
                    className="app-spirit-settings-dropdown"
                    onBlur={function noRefCheck() { }}
                    onChange={this.handleSelectedCultureChange}
                    onClear={function noRefCheck() { }}
                    onFocus={function noRefCheck() { }}
                    onInputChange={function noRefCheck() { }}
                    onMenuClose={function noRefCheck() { }}
                    onMenuOpen={function noRefCheck() { }}
                    onOptionRemove={function noRefCheck() { }}
                    onOptionSelect={function noRefCheck() { }}
                    openMenuOnFocus={function noRefCheck() { }}
                    value={this.state.selectedCulture}
                    options={cultures}
                    clearable={false}
                    placeholder="Please select a culture"
                />
                <br />
                {rightConfiguration}
                {smtpSettings}
                <Button loading={this.state.isSaving} onClick={this.handleClickSave}>
                    Save
                </Button>
                <p>For more information, visit <a href={url} target="_blank" rel="noreferrer">{url}</a></p>
            </div>
        );
    }
}
