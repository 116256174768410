import React, { Component } from 'react';
import { PlaceholderList } from './PlaceholderList';

export class PlaceholderListMonday extends Component {
    static displayName = PlaceholderListMonday.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            id: '',
            content: '',
        };

        this.mondayContext = window.mondayContext;
        this.towerAppInfos = window.towerAppInfos;
        this.handlePlaceholderClick = this.handlePlaceholderClick.bind(this);
        this.createPlaceholderList();
    }

    componentDidMount() {
    }

    createPlaceholderList() {
        this.placeholders = [{
            name: 'Board',
            placeholders: [{ name: 'Name' }, { name: 'Description' }]
        }, {
            name: 'User',
            placeholders: [{ name: 'Name' }, { name: 'Email' }, { name: 'Phone' }]
        }, {
            name: 'General',
            placeholders: [{ name: 'Today', placeholder: 'Today' }]
        }];

        if (this.props.dataSource === 'Board') {
            let itemSumPlaceholderList = this.props.columns
                .filter(c => c.type === 'numbers')  // TODO: More types
                .map(column => ({ name: column.title, placeholder: 'Board.Sum[' + column.title + ']' }));
            if (itemSumPlaceholderList.length > 0) {
                let itemSumPlaceholders = {
                    name: 'Items Sum',                    
                    placeholders: itemSumPlaceholderList.sort((a, b) => a.name.localeCompare(b.name))
                };
                this.placeholders.unshift(itemSumPlaceholders);
            }
        }
        if (this.props.dataSource === 'SingleItem') {
            let columnsForPlaceholders = this.props.columns
                .filter(c => c.type !== 'subtasks' && c.type !== 'button');
            if (this.props.isReportGenerator)
                columnsForPlaceholders = columnsForPlaceholders.filter(c => c.type !== 'file');
            let placeholderList = columnsForPlaceholders
                .map(column => ({ name: column.title, placeholder: this.getPlaceholderFor(column) }));
            placeholderList.push({ name: 'GroupName' });
            let itemPlaceholders = {
                name: 'Item',
                placeholders: placeholderList //.sort((a, b) => a.name.localeCompare(b.name))
            };
            // TODO: Subfields -> .Date / .Start + .End

            if (this.props.subItemsColumns) {
                let subItemSumPlaceholderList = this.props.subItemsColumns
                    .filter(c => c.type === 'numbers')  // TODO: More types
                    .map(column => ({ name: column.title, placeholder: 'Item.Sum[' + column.title + ']' }));
                if (subItemSumPlaceholderList.length > 0) {
                    let subItemSumPlaceholders = {
                        name: 'Subitems Sum',                        
                        placeholders: subItemSumPlaceholderList.sort((a, b) => a.name.localeCompare(b.name))
                    };
                    this.placeholders.unshift(subItemSumPlaceholders);
                }
            }

            this.placeholders.unshift(itemPlaceholders);            
        }
    }

    getPlaceholderFor(column) {
        if (column.type === 'file') {
            return 'Image(100;0):Item.' + column.title;
        }
        if (column.type === 'link') {
            return 'Hyperlink:Item.' + column.title;
        }
        return undefined;
    }

    handlePlaceholderClick(placeholder) {
        this.props.onPlaceholderClick(placeholder);
    }

    render() {
        return (
            <PlaceholderList placeholders={this.placeholders} onPlaceholderClick={this.handlePlaceholderClick} />
        );
    }
}